.about-header{
    min-height: 100vh;
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),
    url('../../Assets/images/contact.jpg');     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
    color: #fff;
    display: flex;justify-content: center;
    align-items: center;
}
.about-header h1{
    font-size: 80px;

}
.about-header p {
    line-height: 1.8;
    font-size: 20px;

}
/* .aboutUs h2{} */
.aboutUs p{
    color: #8E8F9A ;
}


