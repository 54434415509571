.logo-img{
    width: 160px;
}
.logo-name{
    font-size: 25px;
    font-weight: 800;
    color: #3B3663;
    font-family: "Protest Guerrilla", sans-serif;
}
nav .col{
    display: flex;
    justify-content: center !important;
    align-items:  center !important;
}
nav .nav-link{
    font-size: 20px;
    color: #3B3663;
    font-weight: 600;
}
nav .nav-link:hover{
    color: #48C1B9;
}
