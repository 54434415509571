.WhyWithUsHomeSec{
    min-height: 100vh;
    background-color: #F6F6F6;
  
}
.WhyWithUsHomeSec ul {
    padding: 0;
}
.WhyWithUsHomeSec ul li {
    margin: 30px 0;
    color: #8e8f9a ;
    font-size: 18px;
    position: relative;
    padding-left: 20px;
}
.WhyWithUsHomeSec ul li:before{
    content: '';
    position: absolute;
    background-color: #0087B7;
    left: 0;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    

}


