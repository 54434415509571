/* .footer {
    background: #48C1B9;
    color: white;
}
.footer .links ul {
    list-style-type: none;
}
.footer .links li a {
    color: white;
    transition: color 0.2s;
}
.footer .links li a:hover {
    text-decoration: none;
    color: #4180cb;
}
.footer .about-company i {
    font-size: 25px;
}
.footer .about-company a {
    color: white;
    transition: color 0.2s;
}
.footer .about-company a:hover {
    color: #4180cb;
}
.footer .location i {
    font-size: 18px;
}
.footer .copyright p {
    border-top: 1px solid rgba(255, 255, 255, .1);
} */

.footer{
    background-color: #48C1B9;
    color: #fff;
    padding-top:40px ;
    padding-bottom:20px ;
}
.footer h1{
    font-weight: 700;
    /* background-color: #F16461; */
    width: fit-content;
    margin: auto;
    border-radius: 8px;
    padding: 8px;

}
.footer p  {
    font-weight: 600;
    font-size: 20px;
}
.footer .contact-footer i {
    color:  #fff !important;
    font-size: 18px;
}
.footer  ul a{
    text-decoration: none;
    color: #fff;
}
.font-24{
    font-size: 24px;
}
.frame {
    /* background-color: #F16461; */
    /* width: fit-content; */
    margin: auto;
    padding: 8px;
    border-radius: 15px;
}
.footer-header{
    font-family: "Protest Guerrilla", sans-serif;
    color: #1c1c1c;
}