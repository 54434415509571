.holding-home-sec{
    margin: 200px 0;
    background-image: url('https://elevateholding.net/wp-content/uploads/2023/09/red_dots.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
}
.holding{
    width: fit-content;
    margin: auto;
    text-align: center;
    background-color: #48C1B9;

    padding: 5px 40px;
    border-radius: 20px;
    margin:  auto;
    /* background-color: #48C1B9; */
    color: #fff;
    /* position: relative; */
    /* width: 400px; */
    /* height: 40px; */
    
}
.holding h5{
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */

    
}
.holding-home-sec h2{
color: #3B3663;
font-size: 45px;
text-align: center;
font-weight: 900;

}
.holding-home-sec p {
    font-weight: 400;
    color: #5B6674;
    max-width: 800px;
    text-align: center;
    margin: auto;
}