header{
    min-height: 100vh;
    /* background-image: url("../../Assets//images/6248708.jpg");
    background-position: center ;
    background-repeat: no-repeat;
     */
}
.header-main-content{
    font-size: 50px;
    /* width: 50%; */
    color: #3B3663;
}
.header-secondary-content{
    /* width: 50%; */
    font-size: 30px;
    font-weight: 200;
}
@media (max-width:991px) {
    header{
        background-image : none

    }
    .header-main-content ,.header-secondary-content{
        width: 100%;
    }
    
}


.learn-more:hover{
    /* background-color: red !important; */
    color: #59c413 !important;
}
 
