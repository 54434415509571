/* F6F7FB */
.home-srev-sec{
    background-color: #F6F7FB;
}
.shadow{
    box-shadow: 0px 0px 29px 12px rgba(64, 84, 178, 0.06);
}
.home-srev-sec .ico-serv{
    font-size: 40px;
    margin: 10px 0 ;
    /* background-color: #16FF00; */
    padding: 10px;
    color: #F16461;
    border-radius: 50%;
    ;

}
