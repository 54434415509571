
.contact-us{
    color: #1c1c1c1c ;
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),
    url('../../Assets/images/contact.jpg');     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     padding: 50px 0   ;
     background-color: #E6F7FF;
}

.contact-us h4{
    font-size: 25px;
    line-height: 1.6;
    font-weight: 900;
    color: #fff;
    
}
.contact-us h6{
    font-size: 16px;
    font-weight: 350;
    line-height: 1.8;
    color: #fff;
}
.contact-us ul {
    color: #fff;
    padding: 0;
}
.contact-us ul a{
    color: #fff;
}
.contact-us ul .ico {
  
    background-color: #48C1B9;
    color: #fff;
    font-size: 18px;
    padding: 8px;
    border-radius: 50%;
    margin-right: 15px;
    margin-top: 15px;
   

}
.contact-us input,textarea{
    background-color: #daeefa;
    border: none;
    padding: 20px;
}
.contact-inputs {
    justify-content: space-between;

    
}
.contact-inputs input {
    width: 48%;
    margin: 20px 0;
   
    /* gap: 10px; */
}
@media(max-width:991.98px){
    .contact-inputs input{
        width: 100%;
        margin: 0 0 20px 0;
    }

}
/* HTML: <div class="loader"></div> */
.loader {
    width: 120px;
    height: 22px;
    border-radius: 40px;
    color: #daeefa ;
    border: 2px solid;
    position: relative;
  }
  .loader::before {
    content: "";
    position: absolute;
    margin: 2px;
    width: 25%;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background: currentColor;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
      50% {left:100%;transform: translateX(calc(-100% - 4px))}
  }