@import url('https://fonts.googleapis.com/css2?family=Protest+Guerrilla&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 85px;
  /* background-color: red !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-holding {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #48C1B9  !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #3B3663 !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: #3B3663 !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #3B3663 !important;
  --bs-btn-active-border-color: #3B3663;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #3B3663;
  --bs-btn-disabled-border-color: #3B3663 !important;
  padding: 10px !important;
}
.btn-partner {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #0087B7  !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #3B3663 !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: #3B3663 !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #3B3663 !important;
  --bs-btn-active-border-color: #3B3663;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #3B3663;
  --bs-btn-disabled-border-color: #3B3663 !important;
  padding: 10px !important;
}
.btn-orange {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #48C1B9  !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #3B3663 !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: #3B3663 !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #3B3663 !important;
  --bs-btn-active-border-color: #3B3663;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #3B3663;
  --bs-btn-disabled-border-color: #3B3663 !important;
  padding: 10px !important;
}
.headingHomeSec{
  text-align: center;
  font-size: 40px;
color: #3B3663;
}
.headingPara{
  font-size: 20px;
  max-width: 600px;
    font-weight: 200;
    text-align: center;
    margin: auto;
    color: #5b6674;
}
.global-use{
  font-weight: 900;
  color: #3B3663;
  font-size: 45px;

}
ul {
  list-style: none;
}
:root {
  --animate-duration: 500ms !important;
  --animate-delay: .2s !important;
}
body{
  overflow-x: hidden;
  /* background-color: #59c413 !important; */
}
.bg-green-main{
  background-color: #16FF00;
  color: #000;
}
.color-red-alert{
  color: #F8D7DA !important;
} 